//@import "../node_modules/bootstrap/scss/bootstrap.scss";
$blue:    #1A73E8 !default;
$input-btn-focus-width:0px;
$primary-btn-bg:#000;
$input-btn-focus-box-shadow:none;
$orange:#f9a514!default;
$yellow:#f9a514!default;
$red:#f1416c !default;
$green:#36B37E !default;
$gray-600:#7e8299 !default;
$gray-400:#b5b5c3 !default;
$gray-200:#e4e5e9 !default;

$modal-inner-padding:20px;
$modal-content-border-width:0;
$modal-header-border-width: 1px;
$input-group-addon-bg:  #fff;
$border-radius:               .625rem !default;
$btn-border-radius:  .475rem;
$input-border-radius: .475rem;
$input-focus-border-color: $blue;
$input-btn-font-size: 0.9rem;
$border-radius-lg: .475rem;
$form-switch-width:               3em !default;
$form-check-input-width:                  1.5em !default;
$form-check-min-height:                  1.5em !default;
$font-weight-semibold:        500 !default;
$form-label-font-size:                  0.8rem !default;
$form-label-font-weight:                500 !default;

$form-select-indicator-color:       $gray-600 !default;
$input-border-color: #e4e5e9 !default;

$font-weight-bold:500 !default;
$badge-border-radius:               3px !default;
$text-muted:                  $gray-400 !default;


$input-btn-padding-y:         .451rem !default;


$secondary:#E1E3EA;

$body-color: #181c32;
$font-size-base: 0.8rem;
$min-contrast-ratio:   2 !default;
$link-decoration:                         none !default;

$font-family-sans-serif:      'Noto Sans',system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
@import "~bootstrap/scss/bootstrap";

@mixin button-outline-variant(
  $color,
  $bgcolor:tint-color($color, 85%),
  $color-hover: color-contrast($color),
  $active-background: $color,
  $active-border: $color,
  $active-color: color-contrast($active-background)
) {
  --#{$prefix}btn-bg:#{$bgcolor};
  --#{$prefix}btn-color: #{$color};
  --#{$prefix}btn-border-color: #{$bgcolor};
  --#{$prefix}btn-hover-color: #{$color-hover};
  --#{$prefix}btn-hover-bg: #{$active-background};
  --#{$prefix}btn-hover-border-color: #{$active-border};
  --#{$prefix}btn-focus-shadow-rgb: #{to-rgb($color)};
  --#{$prefix}btn-active-color: #{$active-color};
  --#{$prefix}btn-active-bg: #{$active-background};
  --#{$prefix}btn-active-border-color: #{$active-border};
  --#{$prefix}btn-active-shadow: #{$btn-active-box-shadow};
  --#{$prefix}btn-disabled-color: #{$color};
  --#{$prefix}btn-disabled-bg:#{$bgcolor};
  --#{$prefix}btn-disabled-border-color: #{$bgcolor};
  --#{$prefix}gradient: none;
}
@each $color, $value in $theme-colors {
    .btn-outline-#{$color} {
      @include button-outline-variant($value);
    }
  }