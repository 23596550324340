html {
    scroll-behavior: auto!important;
}
:root {
--toastify-font-family:var(--bs-font);
--toastify-icon-color-success:var(--bs-green);
--toastify-icon-color-error:var(--bs-red);
}
.menu-title {
    display: flex;
    font-weight: 500;
    align-items: center;
    flex-grow: 1;
}
.menu-icon1 {
    display: flex;
    align-items: center;
    transition: transform .3s ease;
    transform: rotateZ(0deg);
}
.swal2-title {
    font-weight: 500;
    color: var(--bs-gray-800);
    font-size: 26px;
}
.u_avatar img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
}
.u_avatar div {
    text-transform: uppercase;
    font-size: 0.8em;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}
.u_avatar {
    border-radius: 50%;
    text-align: center;
}
.loginlogo {
    width: 200px;
}
.swal2-html-container {
    font-size: 16px;
    color: var(--bs-gray-600);
}
.rselect__value-container {
    flex-wrap: nowrap!important;
}
.rselect__multi-value {
    flex-shrink: 0;
}
.swal2-confirm {
    background-color: var(--bs-blue)!important;
}
button.swal2-styled {
    border-radius: 7px!important;
    font-size: 0.9rem!important;
    font-weight: 400;
}
.swal2-cancel {
    background-color: var(--bs-gray-200)!important;
    color: var(--bs-body-color)!important;
}
.swal2-icon.swal2-warning {
    border-color: var(--bs-yellow);
    color: var(--bs-yellow);
}
.swal2-styled.swal2-confirm:focus, .swal2-styled.swal2-cancel:focus {
    box-shadow: none!important;
}
.swal2-popup.swal2-modal.swal2-show {
    border-radius: 10px;
}
.pageheader {
    padding: 15px 0px;
}
.text-secondary {
    color:var(--bs-gray-600)!important;
}
.bg-secondary {
    background-color:var(--bs-gray-600)!important;
}
.text-truncate2 {
    display: -webkit-box!important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.c-pointer {
    cursor: pointer;
}
.todoactions {
    display: none;
    background-color: #fff;
    position: absolute;
    right: 0;
}
.todoitm:hover .todoactions {
    display: block;
}
.pageheader h1 {
    font-size: 1.3rem;
    font-weight: 500;
}
.sicon {
    display: flex;
    align-self: center;
    
}
.ant-picker:hover {
    border-color: var(--bs-gray-400);
}
.ant-picker-dropdown {
    z-index: 9999;
}
.ant-picker-focused {
    border-color: var(--bs-blue);
    box-shadow: none;
}

.avatargroup div:not(:first-child) {
    margin-left:-5px;
}
.avatargroup>div {
    border-radius: 100%;
    border: solid 2px #fff;
    cursor: default;
}
.avataritm {
    display: inline-block;
}
.input_i {
    padding-left: 36px;
    
}
.List::-webkit-scrollbar {
    display: none;
  }
  .List {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .dtpicker_calendar {
    box-shadow: 0 0 12px 2px rgb(0 0 0 / 10%);
  }
  .dtpicker_calendar .react-datepicker {
    box-shadow: none!important;
}
.fieldicon {
    position: absolute;
    color: var(--bs-gray);
    font-size: 18px;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    width: 38px;
    text-align: center;
    align-items: center;
    height: 38px;
}
.tagbadge {
    display: inline-flex;
    padding: 2px 10px;
    margin-right: 8px;
    font-size: 12px;
    color: var(--bs-gray);
    background-color: #f2f5f8;
    border-radius: 10px;
}
.tagbadge span {
    font-size: 18px;
    line-height: 15px;
    margin-right: 4px;
}
.rdt_TableHeadRow {
    font-size: 13px;
    text-transform: uppercase;
    color: var(--bs-gray);
}
.menu-icon {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: var(--bs-gray);
    margin-right: 12px;
}
.flex-stack {
    justify-content: space-between;
    align-items: center;
}
.menu-link {
    display: flex;
    padding: 10px 15px;
    width: 100%;
    cursor: pointer;
}
span.active .menu-icon1{
    transform: rotateZ(90deg);
    transition: transform .3s ease;
}
ul.nav-treeview {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-left: 14px;
}
ul.nav-treeview li {
    width: 100%;
}
ul.nav-treeview li a {
    padding: 5px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #7f808c;
    padding-left: 6px;
}
b, strong {
    font-weight: 500;
}
ul.nav-treeview li a:before {
    content: '\2022';
    margin-right: 7px;
    font-size: 20px;
    line-height: 1;
}

  body, html {
   
    background-color: #F2F5F8;
  }
  .card {
    border: none;
    border-radius: 10px;
}
.card-body {
    padding: 30px;
}
.card-header {
    background-color: #fff;
    border-bottom: solid 2px #f3f5f8;
    padding: 20px 30px;
    font-size: 20px;
    font-weight: 500;
}
.btn_indicator {
    display: none;
}
[data-indicator=on]>.btn_indicator {
    display: inline-block;
}
[data-indicator=on]>.btn_title {
    display: none;
}
.alert1 {
    padding: 20px;
    border: dashed 1px;
    background-color: #ddd;
 
}
.clearbtn {
    position: absolute;
    right: 0;
    height: 100%;
    cursor: pointer;
    display: inline-flex;
    z-index: 99;
    padding: 0px 10px;
}
.clearbtn svg {
    align-self: center;
}
.width25 {
    max-width: 350px;
}

.text-blue {
    color: #2980b9;
}
.text-purple {
    color: #8e44ad;
}
.text-red {
    color: #c0392b;
}
.text-orange {
    color: #e67e22;
}
.text-yellow {
    color: #f1c40f;
}
.text-green {
    color: #27ae60;
}
.text-grey {
    color: #495252;
}


.bg-blue {
    background-color: #2980b9;
}
.bg-purple {
    background-color: #8e44ad;
}
.bg-red {
    background-color: #c0392b;
}
.bg-orange {
    background-color: #e67e22;
}
.bg-yellow {
    background-color: #f1c40f;
}
.bg-green {
    background-color: #27ae60;
}
.bg-grey {
    background-color: #495252;
}
.modal-title {
    font-size: 1.2rem;
    font-weight: 500;
}

.tabs .tab-itm {
    padding: 12px;
    display: inline-block;
    text-decoration: none;
    color: var(--bs-gray-600);
    font-weight: 500;
}
.tab-itm.active {
    border-bottom: solid 3px var(--bs-blue);
    color: var(--bs-blue);
}
.tab-itm:hover {
    border-bottom: solid 3px #ddd;
  
}

.imagebtn {
    border-radius: 50%!important;
    height: 38px;
    width: 38px;
    margin: 10px;
    display: inline-flex;
    align-items: center;
    font-size: 18px;
    padding: 8px;
}
.placeholderimg {
    text-align: center;
    font-size: 80px;
    color: #d7d7d7;
    position: absolute;
    top: 50%;
    margin-top: -66px;
    height: auto;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.modalb {
    z-index: 1050!important;
}


.att_day>div:hover > .att_btn {
    display: block!important;
}
.wpx100 {
    width: 100px;
}
.border-dashed {
  border:  dashed 1px var(--bs-border-color) !important;
}
.attendance_table td {
    padding: 0px;
    vertical-align: middle;
}

.att_day {
    border-left: solid 1px var(--bs-gray-200);
    width: 100px;

    position: relative;
    background-color: #fff;
}
.calendarentry {
    min-width: 14.28%;
    max-width: 14.28%;
}
.present p, .absent p {
    margin-bottom: 6px;
    border-left: solid;
    padding-left: 6px;
    font-size: 13px;
}
.present p {
    color: var(--bs-green);
}
.absent p {
    color: var(--bs-red);
}
.text-sm {
    font-size: 10px;
}
.att_btn{
    position: absolute;
    top: 0;
    left: 0;
    background-color: #00000059;
    text-align: center;
    height: 100%;
    width: 100%;
    padding-top: 30px;
}
.list1 {
    border-bottom: dashed 1px var(--bs-gray-400) ;
    padding:10px 0px;
}
.list1:last-child {
    border: none;
}

.menu-itm a:hover {
    background-color: var(--bs-gray-200);
}
.menu-itm {
width:100%;

position: relative;
}
.menuaddnewbtn {
    position: absolute;
    top: 9px;
    right: 8px;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
}
.nav-treeview a {
    border-radius: 20px;
}
.menu-itm>a {
    width:100%;
    display: flex;
    color: var(--bs-gray-800);
    border-radius: 20px;
    padding: 10px 14px;
    text-decoration: none;
    height: 42px;
    }
    .menu-itm .active,.menu-itm .active .menu-icon {
        color: var(--bs-blue);
   
    }
    .menu-itm .active {
        border-radius: 20px;
        background-color: rgba(var(--bs-primary-rgb), 0.1) !important;
    }
    .bgloader{
        height: 200px;
    }

    .modal .bgloader{
        height: 300px;
    }

    .overlayloader {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 99999;
        background-color: #ffffff6e;
        left: 0;
        height: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
.Pending {
    background-color: rgba(var(--bs-warning-rgb), 0.15) !important;
    color:var(--bs-gray);
}
.In-Progress {
    background-color: rgba(var(--bs-primary-rgb), 0.15) !important;
    color:var(--bs-primary);
}
.Hold {
    background-color: rgba(var(--bs-danger-rgb), 0.15) !important;
    color:var(--bs-red);
}

.Completed {
    background-color: rgba(var(--bs-success-rgb), 0.15) !important;
    color:var(--bs-success);
}

    .loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #ffffff;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}


.modal-footer {
    justify-content: flex-start;
}

.uploadbox {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 1px;
    border-radius: 5px;
    border-color: #009ef7;
    border-style: dashed;
    background-color: #f1faff;
    color: rgb(189, 189, 189);
    outline: none;
    transition: border 0.24s ease-in-out 0s;
}

.showscroll::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

.showscroll::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.tableaction {
    display: none;
    position: absolute;
    width: 110px;
    right: 0;
    text-align: right;

}
.pageheader .btn:first-child {
    margin-left: 0px!Important;
}

.btnpulse{
    position: relative;
    transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s; /* Safari */
  }
  

  
  .btnpulse:after {
    content: "";
border-radius: 10px;
    position: absolute;

    left: 0;
    top:0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s;
    box-shadow: 0 0 10px 40px #ddd;
  }
  .rdt_TableCell {
    color: var(--bs-gray);
}

  .btnpulse:active:after {
    box-shadow: 0 0 0 0 #ddd;
    position: absolute;

    left: 0;
    top:0;
    opacity: 1;
    transition: 0s;
  }
  
  .btnpulse:active {
    top: 1px;
  }



.rdt_TableRow:hover .tableaction {
    display: inline;
}


.timeline {
    margin: 0;
    padding: 0;
    list-style: none;
display: flex;
  }
  
  .timeline li {
    text-align: center;
    flex: 1 1 0px;
    color:var(--bs-gray);
    width: 0;
    position: relative;
  }
  
  .timeline li:before {
    content: "";
    line-height: 15px;
    width: 15px;
    height: 15px;
    display: block;
    border-radius: 100%;

    margin: 0 auto 15px auto;
    background: var(--bs-gray);
  }
  
  .timeline li:after {
    content: '';
    width: calc(100% - 15px);
    height: 2px;
    background: var(--bs-gray);
    display: block;
    position: absolute;
    top: 6.5px;
    right: calc(50% + 7.5px);
    z-index: 1;
  }
  
  .timeline li:first-child:after {
    content: none;
  }
  
  .timeline li.active {
    color: var(--bs-green);
  }
  .timeline li.active:before,  .timeline li.active:after {
    background-color: var(--bs-green);
  }
  .timeline li:first-child:before {
    border-color: #8cc413;  
  }
.rselect__value-container {
    padding-top: 0!important;
}
  .rselect__indicator {
    color: var(--bs-gray-400)!important;
}
  .quill {
    border:solid 1px var(--bs-gray-200);
    border-radius: var(--bs-border-radius-lg);

  }
  .daterangebtn {
    border: solid 1px var(--bs-gray-200)!important;
    background-color: #fff;
    text-align: left;
}

.show  .daterangebtn {
border-color: var(--bs-blue)!important;
}
.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    border: solid var(--bs-gray-400);
    border-width: 0 2px 2px 0;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    position: absolute;
    right: 14px;
    top: calc(50% - 6px);
}
  .rselect.is-invalid .rselect__control{
    border-color:var(--bs-red);
  }
  .quill:focus-within {
    border-color:var(--bs-blue);
  }
  .quill.is-invalid {
    border-color:var(--bs-red);
  }
.ql-toolbar.ql-snow {
    border: none;
}
.ql-container.ql-snow {
    border:none;
}
.skeleton {
    background: 
    linear-gradient(90deg,#0000 33%,rgba(192, 192, 192, 0.3) 50%,#0000 66%)
    rgb(224, 224, 224);
  background-size:300% 100%;
  animation: shine 1s infinite;
}
.bulkaction {
    position: fixed;
    bottom: 0;
    left: 0px;
    z-index: 9;
    width: 100%;
    text-align: center;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #00000057;
    padding: 10px;
    overflow: auto;
    white-space: nowrap;
}
@media print {
.webbranding .companyname {
font-size: 25px!important;
}
.webbranding .url {
    font-size: 18px!important;
    }
}
@keyframes shine {
    0% {
      background-position: right;    
    }
    /*100% {
      background-position: left; it's the default value, no need to define it
    }*/
  }

 @keyframes fadeInAnimation {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
.card {
    animation: fadeInAnimation ease .3s;
            animation-iteration-count: 1;
            animation-fill-mode: backwards;
}
@keyframes skeleton-loading {
    0% {
      background-color: #00000020;
    }
    100% {
      background-color: #ffffff90;
    }
  }

@media screen and (max-width: 500px) {
    .tableaction {
        display: inline;
        position: relative;
        width: auto;
        
        text-align: right;
    }
  }
@media screen and (min-width: 991px) {
    .sidebar {
        position: fixed;
        top: 0;
        bottom: 0;
        overflow-y: auto;    left: 0;
        padding: 48px 0 0; /* Height of navbar */
        box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
      }
      .fcolumn .rdt_TableCol:first-child, .fcolumn .rdt_TableCell:first-child {
        position: sticky;
        background-color: #fff;
    
        border-right: solid 1px #ddd;
        left:0;
        z-index: 1;
    }
    .fstable td:first-child, .fstable th:first-child {
        position: sticky!important;
        left: 0;
        background-color: #f2f5f8;
        
        padding: 10px;
        font-weight: 500;
        z-index: 1;
    }
  }
  @media screen and (max-width: 991px) {
    .sidebar {
        width: 100%;
      }
      .calendarentry {
        min-width: 140px;
        max-width: 140px;
    }
    .month_cal_view {
        width: 965px;
    }
    .tablecard {
        padding:5px;
    }
    .rounded-md-start {
        border-radius: var(--bs-border-radius);
        }
  }